import React from "react";
import Box from '@material-ui/core/Box';
import SEO from "./seo.js";
import Header from "./header.js";
import Footer from "./footer.js";
import PropTypes from "prop-types";
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
// import lightBlue from '@material-ui/core/colors/lightBlue';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: indigo[600],
    }
  },
});

class Layout extends React.Component{
  render(){
    var { title, description, lang, meta, keywords, ...other} = this.props;
    return (
      <ThemeProvider theme={theme}>
        <SEO title={title} keywords={keywords} description={description} lang={lang} meta={meta}/>
        <Box className={this.props.banner} component='div'>
          <Header lang={lang} {...other} transparent={this.props.banner? true: false}/>
          <div>
            {this.props.children}
          </div>
        </Box>
        <Footer lang={lang}/>
      </ThemeProvider>
    );
  }
}

Layout.defaultProps = {
  lang: 'cn',
};

Layout.propTypes = {
  lang: PropTypes.string,
};

export default Layout;
