import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import BlurOn from '@material-ui/icons/BlurOn';
import Home from '@material-ui/icons/Home';
import WbSunny from '@material-ui/icons/WbSunny';
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';
import GetApp from '@material-ui/icons/GetApp';
import KeyboardVoice from '@material-ui/icons/KeyboardVoice';
import School from '@material-ui/icons/School';
import { Menu as MenuIcon } from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from "@reach/router"

const getHeaderStyle = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  transparent: {
    flexGrow: 1,
    background: 'transparent',
    boxShadow: 'none'
  },
  normal:{
    flexGrow: 1,
  },
  mobileIcon: {
    color: theme.palette.primary.main,
  },
  mobileMenu: {
    width: '80vw',
  }
}));

const i18n = {
  'cn':{
    home: '首页',
    features: '功能特性',
    quick_start: '快速开始',
    downloads: '下载',
    blogs: '博客',
    docs: '文档与帮助',
  },
  'en':{
    home: 'Home',
    features: 'Features',
    quick_start: 'Quick Start',
    downloads: 'Downloads',
    blogs: 'Blogs',
    docs: 'Docs&Support',
  },
};


class MobileMenus extends React.Component{
  constructor(props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.state = {
      anchorEl: null,
    };
  }

  openMenu(event){
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  closeMenu() {
    this.setState({
      anchorEl: null,
    });
  }

  render(){
    return (
      <Typography component='div'>
        <Button {...this.props} onClick={this.openMenu}>
          <MenuIcon/>
        </Button>
        <Menu
          keepMounted
          anchorEl={this.state.anchorEl}
          onClose={this.closeMenu}
          open={Boolean(this.state.anchorEl)}
          variant='menu'
          className={this.props.menuClass}
        >
          {
            this.props.menu.map((item) => (
              <MenuItem
                key={item.link}
                onClick={() => {
                    navigate(item.link);
                  }
                }
                >
                <ListItemIcon className={this.props.iconClass}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text}/>
              </MenuItem>
            ))
          }
        </Menu>
      </Typography>
    );
  }
}


export default function Header(props){
  const classes = getHeaderStyle();
  var headerClass;
  if(props.transparent){
    headerClass = classes.transparent;
  }else{
    headerClass = classes.normal;
  }
  let langPath;
  if (props.lang === 'cn'){
    langPath = '';
  }else{
    langPath = '/' + props.lang;
  }
  const texts = i18n[props.lang];
  const menus = [
    {
      icon: <Home/>,
      link: langPath + '/',
      text: texts.home,
    },
    {
      icon: <WbSunny/>,
      link: langPath + '/features',
      text: texts.features,
    },
    {
      icon: <FlightTakeoff/>,
      link: langPath + '/quick_start',
      text: texts.quick_start,
    },
    {
      icon: <GetApp/>,
      link: langPath + '/downloads',
      text: texts.downloads,
    },
    {
      icon: <KeyboardVoice/>,
      link: langPath + '/blogs',
      text: texts.blogs,
    },
    {
      icon: <School/>,
      link: langPath + '/docs',
      text: texts.docs,
    },

  ];
  return (
    <AppBar position="static" className={headerClass} color='secondary'>
      <Container maxWidth="lg">
        <Toolbar>
          <BlurOn/>
          <Typography variant="h5" className={classes.title}>
            Nano
          </Typography>
          <Hidden only='xs'>
            {
              menus.map((item) => (
                <Button color="inherit" href={item.link} key={item.link}>{item.text}</Button>
              ))
            }
          </Hidden>
          <Hidden smUp>
            <MobileMenus menu={menus} color="inherit" iconClass={classes.mobileIcon} menuClass={classes.mobileMenu}/>
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
