import React from 'react';
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import favicon from '../../static/favicon.ico';

const i18n = {
  'cn': {
    title: 'Nano开源云平台',
    description: '基于KVM技术，使用Go语言开发，简单易学的虚拟机管理软件，从Web管理门户、主机监控、镜像克隆到故障切换，功能完备，开箱即用，数分钟之内即可将您的服务器集群升级为云主机平台。',
    author: 'akumas',
  },
  'en': {
    title: 'Nano - lightweight open-sourced cloud platform',
    description: 'An easy to learn virtualization management software, develop by Golang, base on KVM, bundled with out-of-box functions from the Web portal, guest monitor, instance clone to failover. Nano can upgrade your server clusters into an IaaS platform in a few minutes.',
    author: 'akumas',
  }
}

function SEO({ description, lang, meta, keywords, title }){
  const defaultValue = i18n[lang];
  const pageDescription = description || defaultValue.description;
  const pageMeta = [
        {
          name: `description`,
          content: pageDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: pageDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: defaultValue.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: pageDescription
        }
      ].concat(
        keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `)
            }
          : []
      ).concat(meta);

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${defaultValue.title}`}
      meta={pageMeta}
      link={[
        { rel: 'shortcut icon', type: 'image/x-icon', href: `${favicon}` },
      ]}
      >
      <script async defer src="https://analytics.umami.is/script.js" data-website-id="90b156c4-205b-4084-b3ff-a1221fe52b26"></script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'cn',
  meta: [],
  keywords: ['云主机','KVM','Go','Golang','虚拟化','实例','开源','免费', '资源池', 'virtualization','instance','open-sourced','free', 'resource pool']
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string
};

export default SEO;
