import React from "react";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { navigate } from "@reach/router"
import chinaIcon from './china.svg'
import unitedStateIcon from './united-states.svg'
import { Location } from '@reach/router';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Hidden from '@material-ui/core/Hidden';

const footerTheme = createMuiTheme({
  palette: {
    primary: {
      main: grey[100],
    },
    secondary:{
      main: blueGrey[500],
    }
  },
});

const getClasses = makeStyles(theme => ({
  footer: {
    height: 160,
    margin: theme.spacing(2),
    padding: 10
  },
  icon: {
    height: 32,
    width: 32
  },
  countryLabel: {
    height: 24,
    width: 24
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
}));

function CountryIcon(props){
  const classes = getClasses();
  const { src, text } = props;
  return (
    <Box m={0} p={0} display='flex' alignItems='center'>
      <Box m={0} p={0}>
        <img src={src} alt={text} className={classes.icon}/>
      </Box>
      <Box m={0} p={1}>
      <Typography component='div'>
        {text}
      </Typography>
      </Box>
    </Box>
  )
}

class CountrySelector extends React.Component{
  constructor(props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.state = {
      anchorEl: null,
    };
    this.countries = [
      {
        id: 'china',
        icon: chinaIcon,
        name: {
          'cn': '中国',
          'en': 'China',
        },
        landing: 'https://nanos.cloud/',
      },
      {
        id: 'us',
        icon: unitedStateIcon,
        name: {
          'cn': '美国',
          'en': 'United State',
        },
        landing: 'https://us.nanos.cloud/en/',
      }
    ];
  }

  openMenu(event){
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  closeMenu() {
    this.setState({
      anchorEl: null,
    });
  }

  render(){
    const { className, location, buttonClass, lang, ...others } = this.props;
    let currentCountry;
    if('us.nanos.cloud' === location.hostname){
      currentCountry = 'us';
    }else{
      currentCountry = 'china'
    }
    let currentLocale;
    this.countries.forEach((country) => {
        if(country.id === currentCountry){
          currentLocale = country.name[lang];
        }
    });

    return (
      <Typography component='div'>
        <Button {...others} onClick={this.openMenu} color='secondary' size='small'>
          <LocationOnIcon fontSize='small' className={buttonClass}/>
           {currentLocale}
        </Button>
        <Menu
          keepMounted
          anchorEl={this.state.anchorEl}
          onClose={this.closeMenu}
          open={Boolean(this.state.anchorEl)}
        >
          {
            this.countries.map((country) => {
              const icon = (
                <CountryIcon src={country.icon} text={country.name[lang]}>
                </CountryIcon>
              )
              if(country.id === currentCountry){
                return (
                  <MenuItem
                    key={country.id}
                    selected={true}
                    >
                    {icon}
                  </MenuItem>
                )
              }else{
                return (
                  <MenuItem
                    key={country.id}
                    onClick={() => {
                        navigate(country.landing);
                      }
                    }
                    >
                    {icon}
                  </MenuItem>
                )
              }
            })
          }
        </Menu>
      </Typography>
    );
  }
}

class LanguageSelector extends React.Component{
  constructor(props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.languages = [
      {
        locale: 'cn',
        name: '简体中文',
        home: '/',
      },
      {
        locale: 'en',
        name: 'English',
        home: '/en/',
      },
    ];
    const { lang } = props;
    let initialText;
    this.languages.forEach(current => {
      if(lang === current.locale){
        initialText = current.name;
      }
    })

    this.state = {
      language: lang,
      anchorEl: null,
      displayText: initialText,
    };
  }

  updateLanguage(lang){
    this.languages.forEach(current => {
      if(lang === current.locale){
        this.setState({
          displayText: current.name,
          anchorEl: null,
        })
      }
    })
  }

  openMenu(event){
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  closeMenu() {
    this.setState({
      anchorEl: null,
    });
  }

  render(){
    const currentLang = this.state.language;
    const { buttonClass, ...others } = this.props;
    return (
      <Typography component='div'>
        <Button {...others} onClick={this.openMenu} color='secondary' size='small'>
          <ChatBubbleOutlineIcon fontSize='small' className={buttonClass}/>
           {this.state.displayText}
        </Button>
        <Menu
          keepMounted
          anchorEl={this.state.anchorEl}
          onClose={this.closeMenu}
          open={Boolean(this.state.anchorEl)}
        >
          {
            this.languages.map((lang) => (
              <MenuItem
                key={lang.locale}
                selected={lang.locale === currentLang}
                onClick={() => {
                    this.updateLanguage(lang.locale);
                    navigate(lang.home);
                  }
                }
                >
                {lang.name}
              </MenuItem>
            ))
          }
        </Menu>
      </Typography>
    );
  }
}

const i18n = {
  'cn':{
    product: '产品',
    manual: '用户手册（必读）',
    manualURL: 'https://nanocloud.readthedocs.io/projects/guide/zh_CN/latest/',
    features: '功能特性',
    featuresURL: '/features',
    quickStart: '快速安装指引',
    quickStartURL: '/quick_start',
    downloads: '本地下载',
    downloadsURL: '/downloads',
    mirror: 'Github镜像',
    mirrorURL: 'https://github.com/project-nano/releases/releases',
    develop: '开发资源',
    api: 'API接口文档',
    apiURL: '/docs',
    wiki: '维基百科',
    community: '社区资源',
    blog: '博客',
    blogURL: '/blogs',
    contact: '联系我们',
    contactURL: '/contact',
    quote: '技术支持',
    quoreURL: '/quote',
    support: (
      <div>
        技术支持QQ群 <br/> (Nano Cloud 819161083)
      </div>
    ),
  },
  'en':{
    product: 'Product',
    manual: 'User Manual(Read First)',
    manualURL: 'https://nanocloud.readthedocs.io/projects/guide/en/latest/',
    features: 'Features',
    featuresURL: '/en/features',
    quickStart: 'Quick Start',
    quickStartURL: '/en/quick_start',
    downloads: 'Local Downloads',
    downloadsURL: '/en/downloads',
    mirror: 'Github Mirror',
    mirrorURL: 'https://github.com/project-nano/releases/releases',
    develop: 'Develop',
    api: 'API References',
    apiURL: '/en/docs',
    wiki: 'Wiki',
    community: 'Community',
    blog: 'Blogs',
    blogURL: '/en/blogs',
    contact: 'Contact Us',
    contactURL: '/en/contact',
    quote: 'Get a Quote',
    quoreURL: '/en/quote',
    support: '',
  }
}

export default function Footer(props){
  const { lang } = props;
  const classes = getClasses();
  const texts = i18n[lang];
  return (
    <ThemeProvider theme={footerTheme}>
    <Box width={1} bgcolor='primary.main'>
    <Container maxWidth='lg'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Grid container>
            <Grid item xs={4} sm={12}>
              <LanguageSelector lang={lang} buttonClass={classes.iconButton}/>
            </Grid>
            <Grid item xs={5} sm={12}>
              <Location>
                {
                  ({location}) => (
                    <CountrySelector className={classes.countryLabel} location={location} buttonClass={classes.iconButton} lang={lang}/>
                  )
                }
              </Location>
            </Grid>
            <Hidden only='xs'>
              <Grid item sm={12}>
                <Box height='7vh'/>
              </Grid>
            </Hidden>
            <Grid item xs={3} sm={12}>
              <Box pl={1}>
                <Typography>
                  © Nano
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box pb={1}>
            <Typography variant='subtitle1'>
              {texts.product}
            </Typography>
          </Box>
          <Typography>
            <Link href={texts.manualURL} underline='none' color='textSecondary'>{texts.manual}</Link>
          </Typography>
          <Typography>
            <Link href={texts.featuresURL} underline='none' color='textSecondary'>{texts.features}</Link>
          </Typography>
          <Typography>
            <Link href={texts.quickStartURL} underline='none' color='textSecondary'>{texts.quickStart}</Link>
          </Typography>
          <Typography>
            <Link href={texts.downloadsURL} underline='none' color='textSecondary'>{texts.downloads}</Link>
          </Typography>
          <Typography>
            <Link href={texts.mirrorURL} underline='none' color='textSecondary'>{texts.mirror}</Link>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Box pb={1}>
            <Typography variant='subtitle1'>
              {texts.develop}
            </Typography>
          </Box>
          <Typography>
            <Link href={texts.apiURL} underline='none' color='textSecondary'>{texts.api}</Link>
          </Typography>
          <Typography>
            <Link href='https://github.com/project-nano' underline='none' color='textSecondary'>Github</Link>
          </Typography>
          <Typography>
            <Link href='https://github.com/project-nano/releases/wiki' underline='none' color='textSecondary'>{texts.wiki}</Link>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Box pb={1}>
            <Typography variant='subtitle1'>
              {texts.community}
            </Typography>
          </Box>
          <Typography>
            <Link href={texts.blogURL} underline='none' color='textSecondary'>{texts.blog}</Link>
          </Typography>
          <Typography>
            <Link href={texts.contactURL} underline='none' color='textSecondary'>{texts.contact}</Link>
          </Typography>
          <Typography>
            <Link href={texts.quoreURL} underline='none' color='textSecondary'>{texts.quote}</Link>
          </Typography>
          <Typography component='div'>
            {texts.support}
          </Typography>
        </Grid>
      </Grid>
      </Container>
      </Box>
    </ThemeProvider>
  );
}
